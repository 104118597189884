var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"menu-nav"},[_c('li',{staticClass:"menu-item menu-item-submenu",class:{
      'menu-item-open': _vm.hasActiveChildren('/general'),
    },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_vm._m(0),_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[_vm._m(1),_c('router-link',{attrs:{"to":"/general/export-settings"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Настройка выгрузки")])])])]}}])}),_c('router-link',{attrs:{"to":"/general/dealers"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Автосалоны")])])])]}}])}),_c('router-link',{attrs:{"to":"/general/brands"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Марки")])])])]}}])}),_c('router-link',{attrs:{"to":"/general/models"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Модели")])])])]}}])}),_c('router-link',{attrs:{"to":"/general/models-equipments"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Модели маркетинга")])])])]}}])}),_c('router-link',{attrs:{"to":"/general/model-groups"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Группы моделей")])])])]}}])}),_c('router-link',{attrs:{"to":"/general/model-photos"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Фото моделей")])])])]}}])}),_c('router-link',{attrs:{"to":"/general/publications"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Публикации")])])])]}}])}),_c('router-link',{attrs:{"to":"/general/publications/1"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Рабочий стол")])])])]}}])}),_c('router-link',{attrs:{"to":"/general/actions"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Экшены")])])])]}}])})],1)])]),_c('li',{staticClass:"menu-item menu-item-submenu",class:{
      'menu-item-open': _vm.hasActiveChildren('/notifications'),
    },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_vm._m(2),_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[_vm._m(3),_c('router-link',{attrs:{"to":"/notifications/scripts"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Скрипты")])])])]}}])}),_c('router-link',{attrs:{"to":"/notifications/templates"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Шаблоны")])])])]}}])})],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"menu-icon flaticon2-list-2"}),_c('span',{staticClass:"menu-text"},[_vm._v("Мобильное приложение")]),_c('i',{staticClass:"menu-arrow"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"menu-item menu-item-parent",attrs:{"aria-haspopup":"true"}},[_c('span',{staticClass:"menu-link"},[_c('span',{staticClass:"menu-text"},[_vm._v("Мобильное приложение")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"menu-icon flaticon2-list-2"}),_c('span',{staticClass:"menu-text"},[_vm._v("Напоминалки")]),_c('i',{staticClass:"menu-arrow"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"menu-item menu-item-parent",attrs:{"aria-haspopup":"true"}},[_c('span',{staticClass:"menu-link"},[_c('span',{staticClass:"menu-text"},[_vm._v("Напоминалки")])])])
}]

export { render, staticRenderFns }